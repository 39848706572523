import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import axios from 'axios'
import Glide from '@glidejs/glide'

Alpine.plugin(persist)
window.Alpine = Alpine
Alpine.start()
console.log("Main JS loaded")
window.axios = axios

window.glide = new Glide('.glide', {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    hoverpause: false,
    gap: 0,
    autoplay: 4000,
    animationDuration: 2000
})

// If you use Vue in your app, uncomment the lines below

// window.Vue = require('vue');
// import Vue from 'vue';
//
// const vm = new Vue({
//     el: '#app'
// });
